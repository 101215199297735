import React from "react"
import PropTypes from "prop-types"

const Content = ({ tag, type, className, children }) => {
  const Tag = tag

  let classNames = ['hcc-content']

  if ( type ) {
    classNames.push(`is-${type}`)
  }

  if ( className ) {
    classNames.push(className)
  }

  return (
    <Tag className={classNames.join(' ')}>
      {children}
    </Tag>
  )
}

Content.defaultProps = {
  tag       : 'div',
  type      : null,
  className : null,
  children  : null,
}

Content.propTypes = {
  tag       : PropTypes.string,
  type      : PropTypes.string,
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Content
